a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.menu {
  background-color: #337ab7;
}

.ui.inverted.menu {
  background: #337ab7;
  max-height: 80px;
}

.item {
  margin: 0;
}

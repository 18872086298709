#uniqueDropdown .menu > .item {
	padding: 10px 15px;
}

#uniqueDropdown .menu {
	max-height: none !important;
}

/* General Styling for the Dropdown */
#uniqueDropdown {
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	font-family: 'Roboto', sans-serif;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

	/* Styling for Dropdown Menu */
	#uniqueDropdown .menu {
		border-radius: 4px;
		border: none;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	}

		/* Hover effect for Dropdown Items */
		#uniqueDropdown .menu > .item:hover {
			background-color: #f5f5f5;
			color: #333;
			border-radius: 3px;
		}

		/* Active (or selected) item styling */
		#uniqueDropdown .menu > .item.active {
			background-color: #e0e0e0;
			color: #111;
		}

.batchingRecord-dropdown .text, .my-dropdown .menu > .item {
	font-size: 12px;
}

.show-grid-container {
	display: grid;
	gap: 1px;
	font-family: 'Roboto', sans-serif;
	grid-template-columns: auto 0;
	padding: 0px;
	border-radius: 0px;
	font-size: 1em;
}

	.show-grid-container div {
		padding: 0px;
		border-radius: 0px;
	}

	.show-grid-container > .create-batch-button {
		margin-bottom: 12px;
		margin-top: 20px;
		margin-left: 180px;
		margin-right: 10px;
		background-color: white;
		color: black;
		border: 2px solid #4CAF50; /* Green */
	}

.warning {
	color: red;
	margin-left: 10px;
}

.flex-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0.2em 0;
	flex-wrap: wrap;
	overflow-x: hidden;
}

	.flex-container .uniform-button {
		width: 20%;
		min-width: 40px;
		overflow: hidden;
		text-overflow: clip;
		white-space: normal;
		height: 50px;
		text-align: center;
		padding: 0.1em;
		margin-bottom: 5px;
		margin-top: 5px;
		flex-basis: calc(50% - 5px);
		background-color: #2185d0; /* Blue all the buttons */
		color: aliceblue;
		border: 1px solid #ccc;
		font-size: 1em;
	}

		/* Keep the same color on hover */
		.flex-container .uniform-button:hover {
			background-color: #2185d0;
		}

		/* Keep the same color on active (when clicked) */
		.flex-container .uniform-button:active {
			background-color: #2185d0;
		}

		/* Keep the same color on focus (when clicked or tabbed) */
		.flex-container .uniform-button:focus {
			background-color: #2185d0;
			outline: none; /* Remove default focus outline if undesired */
		}

		/* Style for disabled buttons */
		.flex-container .uniform-button:disabled {
			background-color: gray; /* Gray color for disabled buttons */
			color: darkgray; /* Optional: change text color for disabled buttons */
			border: 1px solid #aaa; /* Optional: change border color for disabled buttons */
			cursor: not-allowed; /* Optional: show not-allowed cursor on hover */
		}



body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}

#sidebar {
  position: fixed;
  top: 83px; /* Ensure it starts from the top */
  left: 0; /* Align to the left */
  bottom: 75px; /* Stretch to the bottom */
  width: 20%!important; /* Adjust width as necessary */
  background-color: #f5f5f5;
  padding-top: 25px;
  /*padding-bottom: 75px;*/
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

/* Apply scrollbar to sidebar only for smaller screens */
@media (max-width: 768px) {
  #sidebar {
    height: 200px; 
    overflow-y: auto; /* Enable scrollbar for the sidebar when content overflows */
  }
}

#sidebar .ui.menu > a.item {
  padding: 10px 20px;
  line-height: 20px;
  color: #337ab7;
  border-radius: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
}

#sidebar .ui.menu > a.item.active {
  background-color: #337ab7;
  color: white;
  border: none !important;
}

#sidebar .ui.menu > a.item:hover {
  background-color: #eee;
  color: #23527c;
}

#content {
  /*padding-top: 56px;*/
  position: fixed;
  left: 20%;
  top: 83px; /* Ensure it starts from the top */
  bottom: 75px; /* Stretch to the bottom */
  padding-left: 20px;
  padding-right: 20px;
  /*padding-top: 25px;*/
  width: 80% !important; /* Adjust width as necessary */
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

#content h1 {
  font-size: 36px;
}

#content .ui.dividing.header {
  width: 100%;
}

#footer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  /*height:5%;*/
}

.ui.centered.small.circular.image {
  margin-top: 14px;
  margin-bottom: 14px;
}

.ui.borderless.menu {
  box-shadow: none;
  flex-wrap: wrap;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
  display: none;
}
.ui.grid > .row {
  padding-bottom: 1em;
}

.ui.selection.dropdown {
  min-width: 4em;
}
.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

#leftSide {
  display: inline;
  float: left;
}
#rightSide {
  display: inline;
  width: auto;
  float: right;
}
#rightTitle {
  display: inline;
  float: right;
}
#container {
  width: 65%;
}

.ui.table [class*='single line'],
.ui.table[class*='single line'] {
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 10px solid #e0e0e0;
}

/* Media query for screens with width less than or equal to 768px */
@media (max-width: 500px) {
  .ui.table [class*='single line'],
  .ui.table[class*='single line'] {
    white-space: pre-wrap; /* Allow wrapping of table cells on smaller screens */
    font-size: 1px; /* Adjust font size for smaller screens */
  }
}


div.react-datepicker-popper {
  z-index: 4;
}

.scrollable {
  overflow-x: scroll;
  max-height: 75vh;
}

tbody {
  height: auto;
  overflow-y: scroll;
}

thead {
  display: table-header-group;
  table-layout: auto;
}

tbody tr {
  display: table-row;
  table-layout: auto;
}
.last-row {
  background-color: gray; /* Change the color to your desired highlight color */
  color: white; /* Set the font color to white */
  font-weight: bold; /* Make the text bold */
}

.last-row-first-column {
  background-color: gray; /* Change the color to your desired color */
  color: white; /* Set the font color to white */
  font-weight: bold; /* Make the text bold */
  text-align: right;
}




.report-summary {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	padding: 20px;
	margin-top: 20px;
	color: #333;
	font-family: 'Arial', sans-serif;
}

	.report-summary h3 {
		color: #2a2a2a;
		font-size: 24px;
		margin-bottom: 16px;
	}

	.report-summary p {
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 10px;
	}

/* Color scheme for the summary */
.report-summary {
	background-color: #f9f9f9;
	border-left: 4px solid #007bff;
}
